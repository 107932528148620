<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-14 w-auto" src="../../assets/logo.svg" alt="BTE" />
      <h2 class="mt-6 text-center text-2xl font-bold text-gray-900">
        Pieslēgties
      </h2>
    </div>

    <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="login">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              E-pasts
            </label>
            <div class="mt-1">
              <input id="email" name="email" v-model="email" type="text" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              <span v-if="error.email" class="text-sm text-red-500">{{error.email}}</span>
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              Parole
            </label>
            <div class="mt-1">
              <input id="password" name="password" v-model="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              <span v-if="error.password" class="text-sm text-red-500">{{error.password}}</span>
              <span v-if="loginError" class="text-sm text-red-500">{{loginError}}</span>
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
              <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>
          </div>

          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out">
              Pieslēgties
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "Login",
        data: () => ({
            email: '',
            password: '',
            showPassword: false,
            valid: true,
            loginError: null,
            error: {
              email: null,
              password: null,
            },
            passwordRules: [
                v => !!v || 'Parolei jābūt norādītai',
            ],
            emailRules: [
                v => !!v || 'E-pastam jābūt norādītam',
                v => /.+@.+/.test(v) || 'Nav korekta e-pasta adrese'
            ]
        }),
        computed: {
            ...mapGetters({
                errorStatus: 'authErrorStatus',
                errorMessage: 'authErrorMessage',
                user: 'user',
            })
        },
        mounted() {
          if (this.user) {
            this.$router.push('/')
          }
        },
        methods: {
            validEmail: function (email) {
              var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

              return re.test(email);
            },
            requiredField(text) {
              (text.length > 0) ? true : false;
            },
            login() {
              if(!this.validEmail(this.email)) {
                this.error.email = "Nav korekta e-pasta adrese"
                this.valid = false
              }
              if (this.requiredField(this.email)) {
                this.error.email = "E-pastam jābūt norādītam"
                this.valid = false
              }
              if (this.requiredField(this.password)) {
                this.error.password = "Parolei jābūt norādītai"
                this.valid = false
              }

              if (this.valid) {
                  this.$store.dispatch('getToken', {
                      email: this.email,
                      password: this.password
                  })
                  .then(response => {
                      this.$router.push({name: 'home'})
                  })
                  .catch(error => {
                    this.loginError = "Nepareizs e-pasts un/vai parole."
                  })
              }
            }
        }
    }
</script>